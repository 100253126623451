import React, { useState } from 'react';

export default function ContactSection({ light, id }) {
  const [status, setStatus] = useState('');
  const submitForm = (ev) => {
    /*ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus("SUCCESS");
            } else {
                setStatus("ERROR");
            }
        };
        xhr.send(data);*/
  };

  return (
    <section className={light ? 'style5 wrapper' : 'style2 wrapper'}>
      <div className="inner">
        <section>
          <h2>Melde Dich Ungeniert Bei Uns</h2>
          <p>
            Du hast eine Frage? Möchtest dich auf einen Kaffee treffen? Lass es
            uns wissen.
          </p>
          <form method="post" action="https://formspree.io/xleoebjq">
            <input
              type="hidden"
              name="_subject"
              value="Neue Anfrage von Webseite"
            />
            <input type="text" name="_gotcha" style={{ display: 'none' }} />
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue=""
                  placeholder="Name"
                />
              </div>
              <div className="col-6 col-12-xsmall">
                <input
                  type="email"
                  name="email"
                  id="email"
                  defaultValue=""
                  placeholder="Email*"
                  required
                />
              </div>
              <div className="col-12">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Nachricht*"
                  required
                  rows="6"
                ></textarea>
              </div>
              <div className="col-12">
                {status === 'SUCCESS' ? (
                  <p>
                    Vielen Dank für deine Nachricht! Wir werden uns unverzüglich
                    bei dir melden.
                  </p>
                ) : (
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Nachricht versenden"
                        className="primary"
                      />
                    </li>
                    {/*<li><input type="reset" value="Reset"/></li>*/}
                  </ul>
                )}
                {status === 'ERROR' && (
                  <p>
                    Uuuups! Da ist etwas schiefgelaufen. reCAPTCHA ausgefüllt?
                  </p>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    </section>
  );
}
