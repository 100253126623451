import React from 'react';

import Layout from '../components/Layout';
import ContactSection from '../components/Contact-Section';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

const IndexPage = ({ data, location }) => (
  <Layout
    location={location}
    title={'Kontakt'}
    description={`Wir sind gerne für dich da! Kontaktiere
        uns spontan und unverbindlich. Wir freuen uns auf deine Kontaktaufnahme.`}
  >
    <article id="main">
      <header>
        <h2>Kontakt</h2>
        <p>So erreichst du uns</p>
      </header>
      <ContactSection light={true} id={'contact'} />
      <section className="wrapper style2">
        <div className="inner">
          <section>
            <div className="row">
              <div className="col-6 col-12-small">
                <h2>Hier findest du uns</h2>
                <p>
                  Benso GmbH
                  <br />
                  Burgweg 44
                  <br />
                  CH-3270 Aarberg
                </p>
              </div>
              <div className="col-6 col-12-small">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={data.staticMap.mapUrl}
                >
                  <GatsbyImage
                    alt={'standort benso gmbh'}
                    image={
                      data.staticMap.childFile.childImageSharp.gatsbyImageData
                    }
                  />
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);
export const query = graphql`
  query StaticMapQuery {
    staticMap {
      childFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 400, width: 600)
        }
      }
      mapUrl
    }
  }
`;
export default IndexPage;
